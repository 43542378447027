<script setup>
import { computed, inject, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import './ChatGPT.scss'


const store = useStore()

const searchTextAI = computed(() => {
	return store.getters['search/searchText']
})

const isLoading = computed(() => {
	return store.getters['search/isLoading']
})
const userFontSize = computed(() => {
	return store.getters['interfaceSettings/fontSize']
})
onMounted(() => {})

onUnmounted(() => {
})

</script>
<template>
	<div class="gpt-box-container">
		<div class="result-text-wrapper">
			<div class="result-text-holder">
				<div class="gpt-question">
					<p>Explain how to get the most out of ChatGPT. Share tips and give specific examples. Ask me a question to get started.</p>
				</div>
				<div class="gpt-answer">
					<div class="answer-ava">
						<img src="@/assets/images/vector/logo-circle.svg" alt="">
					</div>
					<div class="answer-body"><p>To get the most out of ChatGPT, it's all about asking clear, specific, and context-rich questions. Here are some tips:</p>
						<h3>1. <strong>Be Specific with Your Questions</strong></h3>
						<ul>
							<li><strong>Tip</strong>: The more details you provide, the more accurate and helpful the response will be.</li>
							<li><strong>Example</strong>: Instead of asking, "What can I do with AI?" ask, "How can AI improve customer service in a retail business?"</li>
						</ul>
						<h3>2. <strong>Use Follow-Up Questions</strong></h3>
						<ul>
							<li><strong>Tip</strong>: If you don’t get the answer you’re looking for, ask follow-up questions to clarify or delve deeper.</li>
							<li><strong>Example</strong>: After receiving an initial response, you could ask, "Can you give an example of a successful AI implementation in retail?"</li>
						</ul>
						<h3>3. <strong>Leverage Different Perspectives</strong></h3>
						<ul>
							<li><strong>Tip</strong>: Ask for different viewpoints or scenarios to get a well-rounded answer.</li>
							<li><strong>Example</strong>: "What are the pros and cons of using AI in healthcare?"</li>
						</ul>
						<h3>4. <strong>Experiment with Creativity and Problem Solving</strong></h3>
						<ul>
							<li><strong>Tip</strong>: Use ChatGPT for brainstorming ideas, generating creative content, or solving complex problems.</li>
							<li><strong>Example</strong>: "Help me brainstorm ideas for a tech startup focused on environmental sustainability."</li>
						</ul>
						<h3>5. <strong>Use Context to Your Advantage</strong></h3>
						<ul>
							<li><strong>Tip</strong>: Provide context about your situation, project, or interests to get more tailored advice.</li>
							<li><strong>Example</strong>: "I’m a high school teacher looking to integrate technology into my lessons. What are some engaging ways to use AI tools in the classroom?"</li>
						</ul>
						<h3>6. <strong>Ask for Step-by-Step Instructions</strong></h3>
						<ul>
							<li><strong>Tip</strong>: For tasks or processes, ask for a breakdown of steps.</li>
							<li><strong>Example</strong>: "How can I create a content marketing strategy for a small business?"</li>
						</ul>
						<h3>7. <strong>Clarify or Correct Misunderstandings</strong></h3>
						<ul>
							<li><strong>Tip</strong>: If the answer doesn’t seem right or is off-topic, clarify your question or correct the mistake.</li>
							<li><strong>Example</strong>: "Actually, I was asking about how to use AI in content creation, not customer service."</li>
						</ul>
						<h3>8. <strong>Engage in Iterative Conversations</strong></h3>
						<ul>
							<li><strong>Tip</strong>: Treat the conversation as a collaborative process. Start with a broad question and narrow down as needed.</li>
							<li><strong>Example</strong>: "What are the latest trends in AI?" followed by "Which of these trends have the most impact on education?"</li>
						</ul>
						<h3>9. <strong>Use Examples for Better Understanding</strong></h3>
						<ul>
							<li><strong>Tip</strong>: Ask for examples, case studies, or real-world applications to make the information more practical.</li>
							<li><strong>Example</strong>: "Can you give me an example of a company that successfully used AI for marketing?"</li>
						</ul>
						<h3>10. <strong>Explore Different Types of Content</strong></h3>
						<ul>
							<li><strong>Tip</strong>: Ask for lists, comparisons, summaries, or creative content, depending on your needs.</li>
							<li><strong>Example</strong>: "Can you summarize the key points of the latest AI research paper?" or "Write a short story about a future where AI is part of everyday life."</li>
						</ul>
						<hr>
						<p>To get started, how about you tell me what you're currently working on or interested in? I can tailor my advice based on that!</p>
					</div>
					<div class="answer-btns-box">

					</div>
				</div>
				<div class="gpt-question">
					<p>What time is it?</p>
				</div>
				<div class="gpt-answer">
					<div class="answer-ava">
						<img src="@/assets/images/vector/logo-circle.svg" alt="">
					</div>
					<div class="answer-body">
						<p>This is a very complex question , it is too hard for me sorry...</p>
					</div>
					<div class="answer-btns-box">

					</div>
				</div>
			</div>
		</div>
		<div class="chat-gpt-input-box">
			<div class="gpt-input-holder">
				<form class="chat-gpt-form">
					<div class="gpt-form-holder">
						<div class="input-wrapper">
							<button class="message-btn" type="submit"><img src="@/assets/images/vector/search-icon.svg" alt=""></button>
							<input
								autocomplete="off"
								class="form-control"
								id="searchInput"
								ref="searchInputElem"

								placeholder="Ask a question..."
							/>
						</div>
					</div>

				</form>
			</div>

		</div>
	</div>



</template>
