<script setup>
import './search-table.scss'

import { onMounted, onUnmounted, computed, ref, inject, watch, onBeforeMount, onUpdated } from 'vue'
import { useStore } from 'vuex'
import TableRow from '@/components/search-table/table-row/table-row.vue'

const store = useStore()
import { downloadCSV } from '@/helpers/exportCSV.js'
import { downloadTSV } from '@/helpers/exportTSV.js'
import { downloadXLSX } from '@/helpers/exportXLSX.js'
import PricingWindow from '@/components/pricing-window/pricing-window.vue'
import ModalBox from '@/components/modal-box/modal-box.vue'

const tableWrapperElem = ref()
const scrollerHeadElem = ref()
const scrollerMainElem = ref()
const scrolledPX = ref(0)
const selectedForExportCount = ref(0)
const tableOffsetTop = ref()
const isChildExpanded = ref(false)
const isAiSummaryOpen = ref(false)
const isLoadedAdditionColumns = computed(() => {
	return store.getters['search/isAllAdditionColumnsLoaded']
})
const isFilterDropOpen = ref(false)
const copyTextBtn = ref()
const isAddColumnDropOpen = ref(false)
const isExportDropOpen = ref(false)
const isSidebarHidden = computed(() => {
	return store.getters['interfaceSettings/hiddenSidebar']
})
const summaryAiText = ref('')
watch(
	() => isSidebarHidden.value,
	(isHidden) => {
		setTimeout(() => {
			store.commit('interfaceSettings/setTableWidth', tableWrapperElem.value.getBoundingClientRect().width)
		}, 400)
	},
	{ immediate: false }
)
const searchResultColumns = computed(() => {
	return store.getters['search/resultsData']
})
const searchText = computed(() => {
	return store.getters['search/searchText']
})
const currentResultPage = computed(() => {
	return store.getters['search/currentResultPage']
})
const tableColumns = computed(() => {
	return store.getters['search/allColumns']
})
const tableWidth = computed(() => {
	return store.getters['interfaceSettings/tableWidth']
})
const selectedIds = computed(() => {
	return store.getters['search/selectedIds']
})
const isLoading = computed(() => {
	return store.getters['search/isLoading']
})
const userFontSize = computed(() => {
	return store.getters['interfaceSettings/fontSize']
})
const availableSortFields = computed(() => {
	return store.getters['search/availableSortFields']
})
const selectedSortField = computed(() => {
	return store.getters['search/selectedSortField']
})
const resultsPerPage = computed(store.getters['search/resultsPerPage'])
onBeforeMount(() => {
	window.addEventListener('scroll', handleWindowScroll)
	window.addEventListener('resize', handleWindowResize)
})
onMounted(() => {
	handleWindowResize()
	selectedForExportCount.value = store.getters['search/resultsPerPage']
})
onUpdated(() => {
	if(selectedIds.value.length > 0){
		selectedForExportCount.value = selectedIds.value.length
	}
	else{
		selectedForExportCount.value = store.getters['search/resultsPerPage']
	}

})
onUnmounted(() => {
	window.removeEventListener('scroll', handleWindowScroll)
})

watch(
	() => isLoading.value,
	(loadingState) => {
		if (loadingState === false) {
			handleWindowResize()
		}
	},
	{ immediate: false }
)

//замок закрытый вместо красного малиновый

//сделать подсказки к блоку внизу футер

function handleWindowScroll() {
	if (window.scrollY >= tableOffsetTop.value) {
		tableWrapperElem.value.classList.add('fixed-thead')
	} else {
		tableWrapperElem.value.classList.remove('fixed-thead')
	}
}

function handleWindowResize() {
	tableOffsetTop.value = tableWrapperElem.value.getBoundingClientRect().top + window.scrollY
	//console.log(tableWrapperElem.value.getBoundingClientRect().width)
	store.commit('interfaceSettings/setTableWidth', tableWrapperElem.value.getBoundingClientRect().width)
}

function handleScrollHeadElem(el) {
	scrollerMainElem.value.scrollLeft = el.target.scrollLeft
	scrolledPX.value = el.target.scrollLeft
}

function handleScrollMainElem(el) {
	scrollerHeadElem.value.scrollLeft = el.target.scrollLeft
	scrolledPX.value = el.target.scrollLeft
}

function addRemoveColumn(columnID) {
	const nextActiveStatus = tableColumns.value.find(({ id }) => id === columnID).active
	if (nextActiveStatus) store.commit('search/removeColumn', columnID)
	else store.dispatch('search/addColumn', columnID)
}

function handleCheckBoxItemChange(id) {
	if (selectedIds.value.includes(id) === false) {
		store.commit('search/addSelectedId', id)
	} else {
		store.commit('search/removeSelectedId', id)
	}
	selectedForExportCount.value = selectedIds.value.length
}

function handleRemoveColumn(id) {
	store.commit('search/removeColumn', id)
}

function selectedSentenceHeaderCheckbox(checkbox) {
	if (selectedIds.value.length === searchResultColumns.value.length) {
		selectedIds.value.splice(0)
	} else {
		selectedIds.value.splice(0)
		for (let i = 1; i <= searchResultColumns.value.length; i++) {
			selectedIds.value.push(i)
		}
	}
}

function handleClickResetSorting(event) {
	if (selectedSortField.value.query) {
		store.commit('search/resetSortField')
		store.dispatch('search/search')
	}
}

function handleClickSortBtn(event, selectedField) {
	store.commit('search/updateSortField', selectedField)
	store.dispatch('search/search')
}

function handleClickSortOutside(event) {
	isFilterDropOpen.value = false
}

function handleClickDropColumnsOutside(event) {
	isAddColumnDropOpen.value = false
}

function handleClickExportOutside(event) {
	isExportDropOpen.value = false
}

function getSelectedColumnsArray() {
	let arrayToExport = []
	if (selectedIds.value.length > 0) {
		selectedIds.value.forEach((id, index) => {
			arrayToExport.push(searchResultColumns.value[id - 1])
		})
	} else {
		arrayToExport = searchResultColumns.value
	}
	console.log(arrayToExport)

	return arrayToExport
}

function handleCSVExport() {
	const filename = searchText.value + '- Page ' + currentResultPage.value
	downloadCSV(getSelectedColumnsArray(), tableColumns.value, filename)
}

function handleXLSXExport() {
	const filename = searchText.value + '- Page ' + currentResultPage.value
	downloadXLSX(getSelectedColumnsArray(), tableColumns.value, filename)
}

function handleTSVExport() {
	const filename = searchText.value + '- Page ' + currentResultPage.value
	downloadTSV(getSelectedColumnsArray(), tableColumns.value, filename)
}

function handleAiSummaryBtn() {
	setTimeout(() => {
		summaryAiText.value =
			'Loss of major histocompatibility complex class I (MHC-I) in tumor cells limits the use of immune checkpoint blockade (ICB) in colorectal cancer. Nevertheless, the regulatory mechanism of MHC-I downregulation in tumor cells has not been fully elucidated. Overexpression of CEMIP in tumor tissues is associated with a poor prognosis in colorectal cancer. Here, in this research, we aim to address the role of CEMIP in mediating MHC-I expression in tumor cells and investigate the underlying regulatory mechanisms. Protein levels were analyzed by western blotting. Flow cytometry analysis was used to examine immune cells. Protein-protein interactions were investigated by co-immunoprecipitation and proximity ligation assays. The intracellular trafficking of MHC-I was revealed by an immunofluorescent technique. In addition, the effect of CEMIP on tumor growth and the antitumor efficacy of targeting CEMIP in combination with ICB therapy were evaluated in murine models of colorectal cancer. We reported that CEMIP specifically downregulated the expression of MHC-I on the surface of murine and human colon cancer cells, hindering the cytotoxicity of CD8 T cells. We also demonstrated that CEMIP restricted CD8 T-cell antitumor activities both in vitro and in vivo due to impaired MHC-I-mediated antigen presentation. Correspondingly, the combination of CEMIP inhibition and ICB impeded tumor growth and enhanced therapeutic efficacy. Mechanistically, CEMIP acted as an adaptor for the interaction betweenMHC-I and clathrin, which drove MHC-I internalization via clathrin-dependent endocytosis. Furthermore, CEMIP anchored internalized MHC-I to lysosomes for degradation, disrupting the recycling of MHC-I to the cell surface. Overall, our study unveils a novel regulatory mechanism of MHC-I on tumor cell surfaces by CEMIP-mediated internalization and degradation. Furthermore, targeting CEMIP provides an effective strategy for colorectal cancer immunotherapy.'
	}, 2000)
	isAiSummaryOpen.value = true
}

async function copySummaryAiText() {
	try {
		await navigator.clipboard.writeText(summaryAiText.value)
		copyTextBtn.value.classList.add('done')
		setTimeout(() => {
			copyTextBtn.value.classList.remove('done')
		}, 2000)
	} catch ($e) {
		console.log('Cannot copy')
	}
}

function handleAiSummaryCloseModal() {
	isAiSummaryOpen.value = false
	summaryAiText.value = ''
}
</script>

<template>
	<div class="table-buttons-row">
		<div class="left-col">
			<div class="btn-item item-add-columns jsCurrentParent">
				<button
					class="btn"
					type="button"
					:class="{ active: isAddColumnDropOpen === true }"
					@click="isAddColumnDropOpen = !isAddColumnDropOpen"
				>
					<i><img src="@/assets/images/vector/add-columns-btn.svg" alt="" /></i>
					<span>Add columns</span>
				</button>
				<div class="btn-drop drop-columns" v-if="isAddColumnDropOpen" v-move-outside="handleClickDropColumnsOutside">
					<div class="drop-holder">
						<template v-for="(column, i) of tableColumns" :key="i">
							<label class="custom-checkbox">
								<input
									type="checkbox"
									@change="addRemoveColumn(column.id)"
									name="tableColumns"
									:checked="column.active"
									:value="column.id"
								/>
								<i class="ch-btn"></i><span class="checkbox-holder">{{ column.name }}</span>
							</label>
						</template>
					</div>
				</div>
			</div>
			<div class="btn-item item-filter jsCurrentParent">
				<button
					class="btn"
					type="button"
					:class="{ active: isFilterDropOpen === true }"
					@click="isFilterDropOpen = !isFilterDropOpen"
				>
					<i><img src="@/assets/images/vector/filter-btn.svg" alt="" /></i>
					<span
						>Sort By<em v-if="selectedSortField.query">: {{ selectedSortField.name }}</em></span
					>
				</button>
				<div class="btn-drop" v-if="isFilterDropOpen" v-move-outside="handleClickSortOutside">
					<div class="drop-holder">
						<button
							class="drop-btn"
							@click="handleClickResetSorting"
							:class="{ active: !selectedSortField.query }"
							type="button"
						>
							<span>Default</span>
						</button>
						<template v-for="(sortField, i) of availableSortFields" :key="i">
							<button
								@click="handleClickSortBtn($event, sortField)"
								class="drop-btn"
								:class="{ active: sortField.name === selectedSortField.name }"
								type="button"
							>
								<span>{{ sortField.name }}</span>
							</button>
						</template>
					</div>
				</div>
			</div>
		</div>
		<div class="right-col">
			<div class="btn-item item-ai">
				<button
					class="btn"
					type="button"
					:disabled="isLoadedAdditionColumns === false || selectedIds.length < 1"
					@click="handleAiSummaryBtn"
				>
					<i>Ai</i>
					<span>Summary</span>
				</button>
				<div v-if="isLoadedAdditionColumns === false || selectedIds.length < 1" class="p-tooltip p-tooltip-bottom">
					<span class="p-tooltip-arrow"></span>
					<span class="p-tooltip-text">select 1-5 sentences</span>
				</div>
			</div>
			<div class="btn-item item-export jsCurrentParent">
				<button
					class="btn"
					type="button"
					:disabled="isLoadedAdditionColumns === false"
					:class="{ active: isExportDropOpen === true }"
					@click="isExportDropOpen = !isExportDropOpen"
				>
					<i><img src="@/assets/images/vector/export-btn.svg" alt="" /></i>
					<span>Export (<em>{{ selectedForExportCount }}</em>)</span>
				</button>
				<div class="btn-drop drop-export" v-if="isExportDropOpen" v-move-outside="handleClickExportOutside">
					<div class="drop-holder">
						<button @click="handleXLSXExport" class="drop-btn" type="button"><span>XLS</span></button>
						<button @click="handleTSVExport" class="drop-btn" type="button"><span>TSV</span></button>
						<button @click="handleCSVExport" class="drop-btn" type="button"><span>CSV</span></button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="main-table-box b-table-sticky-header table-responsive" ref="tableWrapperElem">
		<div class="head-table-fix">
			<div class="table-holder">
				<div class="table-frame table-wrapper-x" ref="scrollerHeadElem" @scroll="handleScrollHeadElem">
					<table class="heading-table table b-table">
						<thead>
							<tr>
								<th class="sticky-column">
									<div class="title-box">
										<span>Sentence</span>
										<div class="right-side">
											<div
												class="selected-state"
												:class="[{ hundred: selectedIds.length >= 100 }, { zero: selectedIds.length === 0 }]"
											>
												<em>Selected:</em>
												<span>{{ selectedIds.length }}</span>
												<label class="custom-checkbox" v-if="searchResultColumns">
													<input
														type="checkbox"
														@change="selectedSentenceHeaderCheckbox"
														:checked="selectedIds.length === searchResultColumns.length"
													/>
													<i class="ch-btn"></i>
												</label>
												<label class="custom-checkbox" v-else>
													<input type="checkbox" />
													<i class="ch-btn"></i>
												</label>
											</div>
										</div>
									</div>
								</th>
								<template v-for="(column, i) of tableColumns" :key="i">
									<th :class="'cell-' + column.name.toLowerCase()" v-if="column.active === true">
										<div class="title-box">
											<span>{{ column.name }}</span>
											<button class="global-close-btn" type="button" @click="handleRemoveColumn(column.id)"></button>
										</div>
									</th>
								</template>
							</tr>
						</thead>
					</table>
				</div>
			</div>
		</div>
		<div class="table-wrapper-x table-tbody-wrap" ref="scrollerMainElem" @scroll="handleScrollMainElem">
			<table role="table" class="table" :class="'fontSize-' + userFontSize">
				<tbody role="rowgroup">
					<template v-if="isLoading">
						<tr role="row" v-for="(lazyItem, i) of 3" :key="i">
							<th role="rowheader" scope="row" class="sticky-column">
								<div class="sticky-column-box">
									<div class="sentence-content">
										<div class="_g-lazy-block mod-column"></div>
									</div>
									<div class="sticky-column-footer">
										<div class="panel-elements">
											<div class="result-sentnce-selector">
												<div class="loader-checkbox _g-lazy-block"></div>
											</div>
											<div class="sentence-props">
												<div class="prop lock-status _g-lazy-block"></div>
												<div class="prop citations _g-lazy-block"></div>
												<div class="prop participants _g-lazy-block"></div>
												<div class="prop link _g-lazy-block"></div>
											</div>
										</div>
										<div class="more-btn _g-lazy-block"></div>
									</div>
								</div>
							</th>

							<template v-for="(column, i) of tableColumns" :key="i">
								<td
									:aria-colindex="i"
									role="cell"
									:class="'cell-' + column.name.toLowerCase()"
									v-if="column.active === true"
								>
									<div class="_g-lazy-block mod-column"></div>
								</td>
							</template>
						</tr>
					</template>
					<template v-else v-for="(resultItem, i) of searchResultColumns" :key="++i">
						<table-row
							:data="resultItem"
							:index="i"
							:scrolledPX="scrolledPX"
							:tableWidth="tableWidth"
							@checked="handleCheckBoxItemChange(i)"
						/>
					</template>
				</tbody>
				<!---->
			</table>
		</div>
	</div>
	<Transition name="fade">
		<modal-box class-name="summary-modal" v-if="isAiSummaryOpen === true" @close="handleAiSummaryCloseModal">
			<div class="modal-heading-row">
				<div class="left-col">
					<span class="title">Ai summary</span>
				</div>
				<div class="right-col" v-if="summaryAiText.length > 1">
					<button type="button" ref="copyTextBtn" @click="copySummaryAiText()">
						<i><img src="@/assets/images/vector/clipboard-copy.svg" alt="" /></i>
						<em>Copied</em>
					</button>
				</div>
			</div>
			<div class="summary-text">
				<template v-if="summaryAiText.length < 1">
					<div class="_g-lazy-block"></div>
					<div class="_g-lazy-block"></div>
					<div class="_g-lazy-block"></div>
					<div class="_g-lazy-block"></div>
				</template>
				<template v-else>
					<p>{{ summaryAiText }}</p>
				</template>
			</div>
		</modal-box>
	</Transition>
</template>
