<script setup>
import './header-search.scss'
import { onMounted, onBeforeMount, onUnmounted, computed, ref, onUpdated, inject, watch, onBeforeUpdate } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { api } from '@/arcella-api/api.js'
const router = useRouter()
const props = defineProps({
	isFront: {
		type: Boolean,
		required: false,
		default: false
	},
	autofocus: {
		type: Boolean,
		required: false,
		default: false
	}
})
const store = useStore()
const isLoading = computed(() => {
	return store.getters['search/isLoading']
})
const isFigureSearchStore = computed(() => {
	return store.getters['search/isFigureSearch']
})
const isSearchByFigureUpload = computed(() => {
	return store.getters['search/searchByFigureUpload']
})
const searchTextStore = computed(() => {
	return store.getters['search/searchText']
})
const searchText = ref('')
const isFigureSearch = ref()

const searchFocused = ref(false)
const inputTimer = ref(null)
const searchInputElem = ref()
const isSearching = ref(false)
const suggestionsList = ref([])
const isLoadingSuggestions = ref(false)
const canSuggestionsDrop = ref(false)
onMounted(() => {

})
onBeforeMount(() => {
	isFigureSearch.value = isFigureSearchStore.value
	searchText.value = searchTextStore.value

})

onUnmounted(() => {
	isSearching.value = true
})
watch(
	() => isSearchByFigureUpload.value,
	(isFigureUploadSearch) => {
		if(isFigureUploadSearch === true){
			searchText.value = ''
		}

	},
	{ immediate: false }
)
watch(
	() => searchText.value,
	(searchText) => {
		if(searchText){
			if (searchText.length < 1) {
				clearTimeout(inputTimer.value)
				suggestionsList.value = []
				isLoadingSuggestions.value = false
			}
		}

	},
	{ immediate: false }
)
watch(
	() => searchTextStore.value,
	(stext) => {
		searchText.value = stext
	},
	{ immediate: false }
)
watch(
	() => isFigureSearchStore.value,
	(figureSearch) => {
		isFigureSearch.value = figureSearch
	},
	{ immediate: false }
)
function searchTypeSwitchHandler() {

	isFigureSearch.value = !isFigureSearch.value
	if(searchText.value ){
		if(searchText.value.length > 0){
			router.push({ name: 'SearchPage', params: { s: searchText.value }, query: { figure: isFigureSearch.value }})
			store.dispatch('search/resetAll')
			store.commit('search/searchByFigureUpload', false )
			store.commit('search/isFigureSearch', isFigureSearch.value )
			store.commit('search/searchText', searchText.value)
			store.dispatch('search/search')
		}
	}
	else{
		// //if we have clean input text search
		// console.log(isFigureSearchStore.value)
		// store.commit('search/isFigureSearch', !isFigureSearchStore.value )
		// store.dispatch('search/resetAll')

	}


}
function handleSubmitSearch() {
	if (searchText.value.length > 0 && isSearching.value === false) {
		//router.push({ name: 'SearchPage', params: { s: searchText.value }, query: { ...router.currentRoute.value.query, figure: isFigureSearchStore.value }})

		router.push({ name: 'SearchPage', params: { s: searchText.value }, query: {  figure: isFigureSearch.value }})
		store.commit('search/isFigureSearch', isFigureSearch.value)
		store.commit('search/searchByFigureUpload', false)
		store.commit('search/searchText', searchText.value)
		store.commit('search/histogramChanged', false)
		store.commit('search/wantUpdateHistogram', true)
		store.commit('search/wantResetPagination', true)
		store.commit('search/wantResetOntologies', true)
		store.dispatch('search/search')
	}
	clearTimeout(inputTimer.value)
	isLoadingSuggestions.value = false
	suggestionsList.value = []
}

function handleKeyUp(key) {
	clearTimeout(inputTimer.value)
	canSuggestionsDrop.value = true
	if(searchText.value){
		if (searchText.value.length > 1) {
			inputTimer.value = setTimeout(() => {
				if (key.key !== 'Enter') {
					isLoadingSuggestions.value = true
					getSuggestionsFromAPI()
				}
			}, 250)
		}
	}

}
async function getSuggestionsFromAPI() {
	api.expanded.getSuggestions(searchText.value).then((data) => {
		if(isLoading.value === false){
			suggestionsList.value = data.data
		}
		isLoadingSuggestions.value = false
	})
}
function handleClearBtn() {
	suggestionsList.value = []
	isLoadingSuggestions.value = false
	searchText.value = ''
	store.commit('search/searchText', searchText.value)
	searchInputElem.value.focus()
}
function handleSuggestionClicked(suggest){
	searchText.value = suggest
	searchInputElem.value.focus()
	handleSubmitSearch()
}
function handleSwitcherLabelClick(suggest){
	if(isLoading.value === false){
		searchTypeSwitchHandler()
	}
}
function handleOutsideDropdown(){
	canSuggestionsDrop.value = false
}
function handleSearchByFigureBtn(){
	store.commit('search/searchByFigureModalOpen', true)
}
</script>

<template>
	<form class="search-form jsCurrentParent" @submit.prevent="true" @submit="handleSubmitSearch">
		<div
			class="search-box"

			:class="[
				{ 'not-empty': searchText },
				{ focused: searchFocused },
				{ withSuggestions: suggestionsList.length > 1 && searchText.length > 1 && canSuggestionsDrop === true }
			]"
		>
			<div class="clickable-box" @click="searchInputElem.focus()"></div>
			<div class="suggestions-dropdown"  v-click-outside="handleOutsideDropdown" v-if="suggestionsList.length > 1 && searchText.length > 1 && canSuggestionsDrop === true">
				<div class="suggestions-list" >
					<span v-for="(suggest, i) of suggestionsList" @click="handleSuggestionClicked(suggest)" :key="i">{{ suggest }}</span>
				</div>
			</div>
			<button class="search-btn" type="submit"><img src="@/assets/images/vector/search-icon.svg" alt="" /></button>
			<input
				autocomplete="off"
				class="form-control"
				id="searchInput"
				ref="searchInputElem"
				:autofocus="props.autofocus"
				@blur="searchFocused = false"
				@focus="canSuggestionsDrop = true"
				@keyup="handleKeyUp"
				v-model="searchText"
				placeholder="Type for search"
			/>
			<div class="other-buttons">
				<div class="loadingio-spinner-ellipsis" v-if="isLoadingSuggestions">
					<div class="dots-loader">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</div>
				<button class="clear-btn" type="button" @click="handleClearBtn" v-if="searchText"><i></i></button>
				<div class="search-type-switcher">
					<input
						type="checkbox"
						id="search-images-toggle"
						@change="searchTypeSwitchHandler"
						:checked="isFigureSearch"
					/>
					<label @click="handleSwitcherLabelClick">
						<span class="text">
							<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
								<rect
									x="-1"
									y="1"
									width="26"
									height="26"
									rx="3"
									transform="matrix(-1 0 0 1 26 0)"
									stroke="#958DFC"
									stroke-width="2"
								/>
								<path d="M7 8H21" stroke="#958DFC" stroke-width="2" stroke-linecap="round" />
								<path d="M7 14H21" stroke="#958DFC" stroke-width="2" stroke-linecap="round" />
								<path d="M7 20H21" stroke="#958DFC" stroke-width="2" stroke-linecap="round" />
							</svg>
							<em>Text</em>
						</span>
						<span class="images">
							<svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
								<circle cx="21" cy="7" r="2.25" stroke="#217FEE" stroke-width="1.5" />
								<path
									d="M1 17.5362L6.17545 11.7662C7.00327 10.8432 8.46273 10.8881 9.23233 11.8601L14.4848 18.4941C15.2755 19.4927 16.7857 19.5077 17.596 18.5249L19.683 15.9936C20.5 15.0025 22.0261 15.0276 22.8102 16.0449L27.4 22"
									stroke="#217FEE"
									stroke-width="2"
								/>
								<rect
									x="-1"
									y="1"
									width="26"
									height="26"
									rx="3"
									transform="matrix(-1 0 0 1 26 0)"
									stroke="#217FEE"
									stroke-width="2"
								/>
							</svg>
							<em>Figures</em>
						</span>
					</label>
				</div>
			</div>
		</div>

		<button class="search-by-pic-btn" type="button" @click="handleSearchByFigureBtn">
			<img src="@/assets/images/vector/search-icon-upload.svg" alt="" />
		</button>
		<div class="xs-search-by"></div>
	</form>
</template>
