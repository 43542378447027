<script setup>
import { inject, computed, reactive, ref, onMounted, nextTick, watch, onBeforeMount, onUnmounted } from 'vue'
import './Sidebar.scss'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import HistogramSlider from 'vue3-histogram-slider'
import 'vue3-histogram-slider/dist/histogram-slider.css'
import { useStore } from 'vuex'
import { api } from '@/arcella-api/api.js'
import { useRouter } from 'vue-router'
const router = useRouter()
const store = useStore()
const sidebarElem = ref()
const vueSliderCount = ref()
const papersResultCount = ref(25)
const sidebarPaddingTop = ref(100)

//articleType
const isArticleTypeOpened = ref(true)
const articleTypeCheckboxes = ref(['Meta-Analysis', 'Review', 'Systematic Review', 'Preprint'])
const articleTypeSelected = ref([])

//Text Aviability
const isTextAviabilityOpened = ref(true)
const textAviabilitySelected = computed(() => store.getters['search/textAvailabilitySelected'])
//figures type
const isFiguresTypeOpened = ref(true)
const textAviabilityVariants = computed(() => {
	return store.getters['search/textAvailabilityVariants']
})
const typeOfFigureCheckboxes = computed(() => {
	console.log(store.getters['search/typeOfFigure'])
	return store.getters['search/typeOfFigure']
})
const typeOfFigureSelected = ref([])

//history Slider

const histogramData = computed(() => {
	let data = store.getters['search/histogramData']
	return data.map((d) => new Date(d, 0))
})
const histogramYearMin = computed(() => {
	return store.getters['search/histogramMin']
})
const histogramYearMax = computed(() => {
	return store.getters['search/histogramMax']
})
const isHistogramLoaded = computed(() => {
	return store.getters['search/histogramLoaded']
})
const isSearchByUpload = computed(() => {
	return store.getters['search/searchByFigureUpload']
})
const wantUpdateHistogram = computed(() => {
	return store.getters['search/wantUpdateHistogram']
})
const isFigureSearch = computed(() => {
	return store.getters['search/isFigureSearch']
})
const articlesSelectedStore = computed(() => {
	return store.getters['search/articleTypeSelected']
})
const searchTextStore = computed(() => {
	return store.getters['search/searchText']
})
function prettify(ts) {
	return new Date(ts).toLocaleDateString('en', {
		year: 'numeric'
	})
}
const checkedOntologyCheckboxes = ref([])
const isSidebarHidden = computed(() => {
	return store.getters['interfaceSettings/hiddenSidebar']
})
const defaultSearchText = ref('')
const filterType = computed(() => store.getters['search/filterType'])
const searchMode = computed(() => store.getters['search/searchMode'])
const ontologyList = computed(() => {
	let storeList = store.getters['search/ontologyFilterList']
	const resultArray = reactive([])
	storeList.map((parentItem, parentIndex) => {
		let subItems = parentItem.subItems.filter(function (el) {
			return !el.disabled;
		});
		resultArray.push({name:parentItem.name, subItems: subItems})
	})
	return resultArray
})
const isOntologyLoading = computed(() => store.getters['search/isOntologyLoading'])
onBeforeMount(() => {
	window.addEventListener('scroll', handleWindowScroll)
})
onMounted(() => {
	typeOfFigureSelected.value = typeOfFigureCheckboxes.value
	defaultSearchText.value = searchTextStore.value
})
onUnmounted(() => {
	window.removeEventListener('scroll', handleWindowScroll)
})

watch(
	() => isSidebarHidden.value,
	(isHidden) => {
		if (isHidden === true) {
			document.body.classList.remove('sidebar-showed')
		} else {
			document.body.classList.add('sidebar-showed')
		}
	},
	{ immediate: true }
)
watch(
	() => articleTypeSelected.value,
	(selectedCheckboxes) => {
		store.commit('search/updateArticleTypeSelected', selectedCheckboxes)
		store.commit('search/wantResetPagination', true)
		store.commit('search/wantUpdateHistogram', true)
		store.dispatch('search/search')
	},
	{ immediate: false, deep: true }
)
watch(
	() => typeOfFigureCheckboxes.value,
	(selectedCheckboxes) => {
		store.commit('search/wantResetPagination', true)
		store.commit('search/wantUpdateHistogram', true)
		store.dispatch('search/search')
	},
	{ immediate: false, deep: true }
)

function handleWindowScroll(e) {
	let scrollY = document.documentElement.style.getPropertyValue('--scroll-y')
	const scrollYFake = document.documentElement.style.getPropertyValue('--scroll-y-fake')

	if (scrollYFake) {
		scrollY = scrollYFake
	}
	if (scrollY < 100) {
		sidebarElem.value.classList.remove('sidebar-fix')
		sidebarPaddingTop.value = 100 - scrollY
	} else {
		sidebarElem.value.classList.add('sidebar-fix')
	}
}
function toggleSidebar() {
	store.commit('interfaceSettings/hideSidebar', !isSidebarHidden.value)
}
function handleSelectArticleAll() {
	articleTypeSelected.value = articleTypeCheckboxes.value
}
function handleSelectArticleNone() {
	articleTypeSelected.value = []
}
function handleSelectArticleCheckbox(element) {
	let checkedText = articleTypeCheckboxes.value[element.target.value]
	if (element.target.checked === true) {
		if (!articleTypeSelected.value.includes(articleTypeCheckboxes[element.target.value])) {
			articleTypeSelected.value.push(checkedText)
		}
	} else {
		articleTypeSelected.value = articleTypeSelected.value.filter((e) => e !== checkedText)
	}
}

function handleSelectTextAviRadiobutton(element) {
	store.commit('search/updateTextAvailabilitySelected', element.target.value)
	store.commit('search/wantResetPagination', true)
	store.commit('search/wantUpdateHistogram', true)
	store.dispatch('search/search')
}

function changeResultsCount() {
	const sliderValue = vueSliderCount.value.getValue()
	store.commit('search/changeResultsPerPage', sliderValue)
	store.commit('search/wantResetPagination', true)
	store.dispatch('search/search')
}
function handleHistogramChange(e) {
	store.commit('search/histogramChanged', true)
	store.commit('search/updateYearFrom', e.from_pretty)
	store.commit('search/updateYearTo', e.to_pretty)
	store.commit('search/wantResetPagination', true)
	store.dispatch('search/search')
}

function handleSelectFiguresAll() {
	typeOfFigureSelected.value.map((chk) => (chk.selected = true))
}
function handleSelectFiguresNone() {
	typeOfFigureSelected.value.map((chk) => (chk.selected = false))
}
function handleSelectFiguresCheckbox(element, index) {
	typeOfFigureSelected.value[index].selected = element.target.checked
	store.commit('search/updateTypeOfFigures', typeOfFigureSelected.value)
}
function handleSelectOntologyCheckbox(element, index, isChild, childIndex) {


	if(isChild === false){
		if (element.target.checked === true) {
			checkedOntologyCheckboxes.value.push(ontologyList.value[index].name)
		} else {
			let foundedIndex = checkedOntologyCheckboxes.value.findIndex((e) => e === ontologyList.value[index].name)
			checkedOntologyCheckboxes.value.splice(foundedIndex, 1)
		}
	}
	else{
		if (element.target.checked === true) {
			console.log(ontologyList.value[index].subItems[childIndex].name)
			checkedOntologyCheckboxes.value.push(ontologyList.value[index].subItems[childIndex].name)
		} else {
			let foundedIndex = checkedOntologyCheckboxes.value.findIndex((e) => e === ontologyList.value[index].name)
			checkedOntologyCheckboxes.value.splice(foundedIndex, 1)
		}
	}


	store.commit('search/isFigureSearch', false)
	store.commit('search/searchByFigureUpload', false)
	store.commit('search/histogramChanged', true)
	store.commit('search/wantUpdateHistogram', true)
	store.commit('search/wantResetPagination', true)

	store.commit('search/ontologiesSelect', checkedOntologyCheckboxes.value)
	store.dispatch('search/search')
		typeOfFigureSelected.value[index].selected = element.target.checked
}
function handleSelectOntologyChildCheckbox(element, index, childIndex) {
	console.log(`checked ${index} parent and childIndex ${childIndex}`)
	//ontologiesArray.value[index].expanded = true
	//ontologiesArray.value[index].ontologyItems[childIndex].selected = true
	console.log(ontologyList.value[index].subItems[childIndex])
}

function expandOntologyItems(element, index) {
	ontologyList.value[index].expanded = !ontologyList.value[index].expanded

	console.log(ontologyList.value[index])
}

function handleChangeFilterType(filterID) {
	store.commit('search/changeFilterType', filterID)
}

watch(
	() => filterType.value,
	(filterType) => {
		if (filterType === 2) {
			store.commit('search/ontologiesUpdate', store.getters['interfaceSettings/apiKey'])
		}
	},
	{ immediate: true }
)
</script>
<template>
	<aside class="filter-sidebar" ref="sidebarElem" :style="{ paddingTop: sidebarPaddingTop + 'px' }">
		<button class="show-sidebar-btn jsSidebarSwitch" type="button" @click="toggleSidebar">
			<span>
				<i><img src="@/assets/images/vector/short-arrow.svg" alt="" /></i>
				<i><img src="@/assets/images/vector/short-arrow.svg" alt="" /></i>
			</span>
		</button>
		<div class="sidebar-holder">
			<div class="filter-type-change-box" :class="{ onthologyLeave: searchMode !== 2 }">
				<Transition name="slidedown">
					<div class="widget-box slideDownWidget nohr" v-if="searchMode === 2 || searchMode === 4">
						<span class="widget-title">Filter type</span>
						<div class="advanced-search-filter-box">
							<div class="filters-select-box">
								<button :class="{ active: filterType === 1 }" @click="handleChangeFilterType(1)">
									<span>Basic</span>
								</button>
								<button :class="{ active: filterType === 2 }" @click="handleChangeFilterType(2)">
									<span>Ontologies</span>
								</button>
								<button :class="{ active: filterType === 3 }" @click="handleChangeFilterType(3)">
									<span><img src="@/assets/images/vector/data-analitics-icon.svg" alt="" /></span>
								</button>
							</div>
						</div>
					</div>
				</Transition>
			</div>
			<div class="onthology-abs-box">
				<div class="loading-indicator-box showed" v-if="isOntologyLoading === true">
					<span>Loading ontologies</span>
					<i><img src="@/assets/images/vector/loading-columns.svg" alt="" /></i>
				</div>
				<Transition name="fade-right">
					<div class="ontology-abs-holder trans-box" v-if="filterType === 2 || filterType === 3">
						<div class="onthology-checkboxes">
							<div class="loader-marker" v-if="isOntologyLoading === true">
								<img src="@/assets/images/vector/loading-columns.svg" alt="" />
							</div>

							<div class="checks-list">
								<template v-for="(checkbox, i) of ontologyList" :key="i">
									<div class="check-item">
										<div class="item-checkbox">
											<label class="custom-checkbox">
												<input
													type="checkbox"
													@change="handleSelectOntologyCheckbox($event, i, false)"
													name="figuresType"
													:value="i"
												/>
												<i class="ch-btn"></i>
											</label>
											<button
												type="button"
												class="checkbox-text"
												:class="{ active: checkbox.expanded === true }"
												@click="expandOntologyItems($event, i)"
											>
												<span>{{ checkbox.name }}</span> <em>{{ checkbox.subItems.length }}</em>
											</button>
										</div>
										<slide-up-down :active="checkbox.expanded === true" :duration="500">
											<ul class="child-list">
												<li class="child-item" v-for="(subItem, ichild) of checkbox.subItems" :key="ichild">
													<label class="custom-checkbox" v-if="!subItem.disabled">
														<input
															type="checkbox"
															@change="handleSelectOntologyCheckbox($event, i, true, ichild)"
															name="figuresType"
															:value="ichild"
														/>
														<i class="ch-btn"></i>
														<span class="checkbox-holder">{{ subItem.name }} <em>{{subItem.count}}</em></span>
													</label>
												</li>
											</ul>
										</slide-up-down>
									</div>
								</template>
							</div>
						</div>
					</div>
				</Transition>
			</div>
			<Transition name="fade-left">
				<div class="standart-widgets-wrapper trans-box" v-if="filterType === 1">
					<div class="widget-box">
						<span class="widget-title">Information from papers</span>
						<div class="slider-simple">
							<vue-slider
								ref="vueSliderCount"
								:max="100"
								:data="[5, 25, 50, 75, 100]"
								:marks="[5, 25, 50, 75, 100]"
								:height="8"
								:dot-size="18"
								:contained="false"
								:model-value="papersResultCount"
								@drag-end="changeResultsCount"
								:drag-on-click="true"
								:tooltip="'none'"
							/>
						</div>
					</div>
					<div class="widget-box">
						<span class="widget-title">Results by year</span>
						<div class="history-slider">
							<HistogramSlider
								v-if="isHistogramLoaded"
								width="100%"
								:bar-height="100"
								:handleSize="20"
								:keyboard="false"
								:histSliderGap="0"
								:barRadius="3"
								:barGap="5"
								:barWidth="3"
								:lineHeight="8"
								:data="histogramData"
								:prettify="prettify"
								:drag-interval="true"
								:force-edges="true"
								primaryColor="#14C68C"
								@finish="handleHistogramChange"
								:colors="['#3ED67B', '#4facfe']"
								:min="new Date(histogramYearMin, 1, 1).valueOf()"
								:max="new Date(histogramYearMax, 1, 1).valueOf()"
							/>
							<div class="histogram-lazy _g-lazy-block" v-else></div>
						</div>
					</div>
					<div class="widget-box" v-if="isFigureSearch === true && isSearchByUpload === false">
						<button
							class="widget-collapse-title"
							type="button"
							:class="{ active: isFiguresTypeOpened }"
							@click="isFiguresTypeOpened = !isFiguresTypeOpened"
						>
							<span>Type of Figure</span>
						</button>
						<slide-up-down :active="isFiguresTypeOpened" :duration="500">
							<div class="widget-collapse-box showed">
								<div class="select-btns-row">
									<span class="sel-label">Select</span>
									<div class="check-list-horiz">
										<button
											class="sel-btn"
											:class="{ actived: !typeOfFigureSelected.find((e) => e.selected === false) }"
											@click="handleSelectFiguresAll"
											type="button"
										>
											All
										</button>
										<button class="sel-btn" @click="handleSelectFiguresNone" type="button">None</button>
									</div>
								</div>
								<div class="checks-list">
									<template v-for="(checkbox, i) of typeOfFigureCheckboxes" :key="i">
										<label class="custom-checkbox">
											<input
												type="checkbox"
												:checked="checkbox.selected === true"
												@change="handleSelectFiguresCheckbox($event, i)"
												name="figuresType"
												:value="i"
											/>
											<i class="ch-btn"></i>
											<span class="checkbox-holder">{{ checkbox.name }}</span>
										</label>
									</template>
								</div>
							</div>
						</slide-up-down>
					</div>
					<div class="widget-box" v-if="isFigureSearch === false">
						<button
							class="widget-collapse-title"
							type="button"
							:class="{ active: isTextAviabilityOpened }"
							@click="isTextAviabilityOpened = !isTextAviabilityOpened"
						>
							<span>Text availability </span>
						</button>
						<slide-up-down :active="isTextAviabilityOpened" :duration="500">
							<div class="widget-collapse-box">
								<div class="checks-list">
									<template v-for="(item, i) of textAviabilityVariants" :key="i">
										<label class="custom-checkbox type-radio">
											<input
												type="radio"
												:checked="textAviabilitySelected === item.value"
												@change="handleSelectTextAviRadiobutton"
												name="textAviability"
												:value="item.value"
											/>
											<i class="ch-btn"></i>
											<span class="checkbox-holder">{{ item.title }}</span>
										</label>
									</template>
								</div>
							</div>
						</slide-up-down>
					</div>
					<div class="widget-box">
						<button
							class="widget-collapse-title"
							type="button"
							:class="{ active: isArticleTypeOpened }"
							@click="isArticleTypeOpened = !isArticleTypeOpened"
						>
							<span>Article type</span>
						</button>
						<slide-up-down :active="isArticleTypeOpened" :duration="500">
							<div class="widget-collapse-box showed">
								<div class="select-btns-row">
									<span class="sel-label">Select</span>
									<div class="check-list-horiz">
										<button
											class="sel-btn"
											:class="{ actived: articleTypeSelected.length === articleTypeCheckboxes.length }"
											@click="handleSelectArticleAll"
											type="button"
										>
											All
										</button>
										<button class="sel-btn" @click="handleSelectArticleNone" type="button">None</button>
									</div>
								</div>
								<div class="checks-list">
									<template v-for="(checkbox, i) of articleTypeCheckboxes" :key="i">
										<label class="custom-checkbox">
											<input
												type="checkbox"
												:checked="articleTypeSelected.includes(checkbox)"
												@change="handleSelectArticleCheckbox"
												name="articleType"
												:value="i"
											/>
											<i class="ch-btn"></i>
											<span class="checkbox-holder">{{ checkbox }}</span>
										</label>
									</template>
								</div>
							</div>
						</slide-up-down>
					</div>
				</div>
			</Transition>
		</div>
	</aside>
</template>
